
import VueRouter from 'vue-router';

const router = new VueRouter({
    mode: "hash", 
    // mode: "history",
    // base: '/p_index',  // 如果使用history模式，必须设置base参数
    routes: [{
            path: '/',
            redirect: '/home'
        },
        {
            path: '/home',
            name: 'home',
            meta: { 
                title:'融查查——融查查帮你查 使用更安全'
             },
            component: () => import('@/pages/home/index.vue')
        },
        {
            path: '/rongchacha',
            name: 'rongchacha',
            meta: { 
                title:'融查查——融查查帮你查 使用更安全'
             },
            component: () => import('@/pages/rongchacha/index.vue')
        },
    ]
    
});
export default router
router.afterEach(() => {window.scrollTo(0,0)});
router.beforeEach((to, from, next) => {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.location.href = 'https://rcc365.com/plat/h5/'
      return
    }
    next()
  });