
import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
import router from './router/index.js'

import '@/assets/css/common.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import Header from '@/components/header/header.js';
import Footer from '@/components/footer/footer.js';

Vue.use(VueRouter)

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Header);
Vue.use(Footer);

router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title = to.meta.title
  }
  next();
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
