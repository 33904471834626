<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #333;
  /* margin: 0; */
  background: #fff;
  font-size: 16px;
  /* margin-top: 60px; */
}
</style>
