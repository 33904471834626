<template>
  <div>
    <div class="fots">
      <div class="w1200">
        <p>
          数据来源:
          <span>全国企业信用信息公示系统</span>
          <span>中国裁判文书网</span>
          <span>中国执行信息公开网</span>
          <span>国家知识产权局</span>
          <span>商标局</span>
          <span>版权局</span>
        </p>
        <p>
          风险声明：
          任何投资都具备高风险，投资需谨慎，更需要合法。融查查网站数据，及融查查网站内所有用户提供的所有信息，供用户免费查询，但仅作为参考与研究用途。
          不构成对任何用户的投资建议，融查查不做任何推荐，用户需自行承担投资风险。版权所有，转载请注明来源。市场有风险，投资需谨慎。
        </p>
      </div>
    </div>
    <div class="footer">
      <div class="w1200 foot">
        <div class="f-left">
          <img class="logo" src="@/assets/imgs/logo.png" alt="" />
          <div>
            <p>
              <span
                v-for="item in list"
                :key="item.title"
                @click="goNext(item.name)"
                >{{ item.title }}</span
              >
            </p>
            <p>
              版权所有：深圳市一融管理信息技术有限公司 @2023 融查查
              <a
                href="https://beian.miit.gov.cn"
                target="_blank"
              >
                粤ICP备2023095783号
              </a>
            </p>
          </div>
        </div>
        <p>违法和不良举报电话：13302935207</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          title: "关于我们",
          name: "aboutus",
        },
        {
          title: "联系我们",
          name: "contactus",
        },
        {
          title: "用户协议",
          name: "agreement",
        },
        {
          title: "免责声明",
          name: "statement",
        },
      ],
    };
  },
  methods: {
    goNext(name) {
      console.log(name);
      this.$router.push({
        name: "rongchacha",
        query: {
          name: name,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.fots {
  background: #2b343d;
  padding: 20px 0;
  color: #b5b5b5;
  p {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.6;
    span {
      margin-left: 5px;
    }
  }
}
.footer {
  background: #141d26;
  color: #fff;
  padding: 20px 0;
  font-size: 12px;
  color: #999;
  line-height: 1.7;

  .foot {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .f-left {
      display: flex;
      align-items: center;
      cursor: pointer;
      .logo {
        width: 46px;
        height: 46px;
        margin-right: 30px;
      }
      a{
        color: #999;
      }
      span {
        margin-right: 10px;
      }
    }
  }
}
</style>